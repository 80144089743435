<script lang="ts" setup>
import { computed } from 'vue'
import RoomieFooter from '@lahaus-roomie/roomie/src/components/RoomieFooter/index.vue'

import AppHeader from '@/components/AppHeader/index.vue'
import AppNotificationsTrack from '@/components/App/NotificationsTrack/index.vue'

import countries from '@/config/countries.json'
import socialNetworks from '@/config/socialNetworks.json'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})
const htmlAttrs = computed(() => head.value.htmlAttrs!)

const route = useRoute()

useHead({ link: [{ rel: 'canonical', href: route.path }] })

useHead({
  meta: [
    {
      name: 'ahrefs-site-verification',
      content: 'f8f3f12ecdea01cac5531f59cf6aad1313d2527ad6357bad6d45b47211b81894'
    }
  ]
})

const { locale } = useI18n()
const selectedCountry = () => {
  return locale.value === 'co' ? countries[1] : countries[0]
}

const socialNetworksByLocale = computed(() => {
  return (socialNetworks as Record<string, { id: number; name: string; link: string }[]>)[locale.value]
})
</script>

<script lang="ts">
export default {
  name: 'LayoutDefault'
}
</script>

<template>
  <Html
    :lang="htmlAttrs.lang"
    :dir="htmlAttrs.dir">
    <AppNotificationsTrack />

    <div id="app">
      <AppHeader />

      <main class="mt-60">
        <slot />
      </main>

      <NuxtLazyHydrate when-visible>
        <RoomieFooter
          class="roomie-footer"
          :countries="[selectedCountry()]"
          :social-networks="socialNetworksByLocale"
          copyright-text="LaHaus"
          :applications-links="[]" />
      </NuxtLazyHydrate>
    </div>
  </Html>
</template>

<style lang="scss">
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  > main {
    flex: 1 0 auto;
  }

  .container {
    max-width: 1440px;
    padding-left: 80px !important;
    padding-right: 80px !important;

    @media (width >= 1440px) {
      padding-left: 80px !important;
      padding-right: 80px !important;
    }

    @media (width <= 1128px) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    @media (width <= 950px) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    @media (width <= 744px) {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }

  .roomie-footer {
    &__navigation-title {
      @apply font-bold;
    }
  }
}
</style>
